import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import CurrentTime from './CurrentTime'
import artisanWineCoLogoAndIcon from '../assets/images/artisan-wine-co-logo-and-icon.png'

//preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

const Footer = ({disabledMenu}) => {
  const queryData = useStaticQuery(graphql`
  {
    prismicFooterMenu {
      id
      _previewable
      data {
        menu_links {
          label {
            text
          }
          link {
            url
            id
            isBroken
            lang
            link_type
            raw
            size
            slug
            tags
            target
            type
            uid
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData(queryData)

  const navigation = data.prismicFooterMenu
  const footerLinks = navigation.data.menu_links
  const footerLinksLength = footerLinks.length

  return (
    <footer className="container-fluid footer pt-5 pb-4 px-0">
      <div className='container'>
        <div className="row py-lg-5">
          <div className="col-lg-5 pl-3 pr-5 content-wrapper">
            <div className='footer__logo'>
              <img src={artisanWineCoLogoAndIcon} alt="Artisan Wine Co Logo And Icon" width="100%"/>
            </div>
          </div>
          <div className="col-lg-4 pt-5 pt-lg-0">
          {(disabledMenu) ? [] :
            <ul className="list-unstyled">
              {
                footerLinks.map((navItem, index) => {
                  return (
                    (index < footerLinksLength-1) ?
                      (navItem.link.link_type === "Document" && navItem.link.uid)?
                        <li key={`link-${index}`} className='nav-item doc pb-4 mb-2'>
                          <Link to={"/" + navItem.link.uid}>
                            {navItem.label.text}
                          </Link>
                        </li>
                        : <li key={`link-${index}`} className='nav-item web pb-4 mb-2'>
                          <a href={(navItem.link.url)? navItem.link.url : "/"} target={(navItem.link.target)? navItem.link.target : "_self"}>
                            {navItem.label.text}
                          </a>
                        </li>
                    : null
                  )
                })
              }
            </ul>
          }
          </div>
          <div className="col-lg-3 d-flex flex-column justify-content-between">
            <ul className="list-unstyled">
              {footerLinks.map((navItem, index) => {
                return (
                  (index === footerLinksLength - 1) ?
                    (navItem.link.link_type === "Document" && navItem.link.uid)?
                    <li key={`link-${index}`} className="nav-item pb-4 mb-2">
                      <Link to={"/" + navItem.link.uid}>
                          {navItem.label.text}
                      </Link>
                    </li>
                    : <li key={`link-${index}`} className='nav-item web pb-4 mb-2'>
                      <a href={(navItem.link.url)? navItem.link.url : "/"} target={(navItem.link.target)? navItem.link.target : "_self"}>
                        {navItem.label.text}
                      </a>
                    </li>
                  : null
                )
              })}
            </ul>
            <div className='footer__brand-info pb-4 screen-only'>
              <div className='brand-name pb-2'>
                <strong>Artisan Wine Co.</strong>
              </div>
              <div className='brand-address'>PO Box 474, Oliver, BC V0H</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom pt-5 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 tc-wrapper">
              <Link to='/privacy-policy' title='Privacy Policy' className='pb-4 pb-lg-0'>
                Privacy Policy
              </Link>
              <span className="mx-2">|</span>
              <Link to='/terms-and-conditions' title='Terms & Conditions' className='pb-4 pb-lg-0'>
                Terms & Conditions
              </Link>
            </div>
            <div className="col-lg-6 copyright-wrapper">
              <div className="d-flex justify-content-end">
                &copy; Artisan Wine Co, &nbsp; <CurrentTime /> <span className="mx-1 mx-sm-2">|</span>All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
      <script
            key="privacy-policy-script"
            type="text/javascript" 
            src={`https://gpp.markanthony.com/privacy-policy.js?entity=MAG&language=en&variables=true`} 
            defer
        ></script>
    </footer>
  )
}

export default Footer
