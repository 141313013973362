// core
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import $ from 'jquery'
import { useLocation } from '@reach/router';
import { scroller } from "react-scroll";

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import CommonPageBanner from '../components/CommonPageBanner'


const PrivacyPolicyPage = ({data}) => {

  const [policyContent, setPolicyContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeKey, setActiveKey] = useState(null)
  const isBrowser = typeof window !== "undefined"
  const location = useLocation()
  const [baseURL] = useState(location?.href)

  const pageData = data.prismicGeneralDocumentsPage.data

  const handleAccordionToggle = (eventKey, eventObj) => {
    setActiveKey(eventKey === activeKey ? null : eventKey)
  }

  const handlePrivacyCenterClick = (event) => {
    event.preventDefault()
    window.semaphore.push(['showPreferences'])
  }

  useEffect(() => {
    const privacyCenterLinks = document.querySelectorAll('.privacy-center-toggle')
    privacyCenterLinks.forEach(link => {
      link.addEventListener('click', handlePrivacyCenterClick)
    });

    return () => {
      privacyCenterLinks.forEach(link => {
        link.removeEventListener('click', handlePrivacyCenterClick)
      })
    }
  }, [])

  useEffect(() => {
    if (!isBrowser) {
      return
    } else if(isBrowser && baseURL.includes('#')) {
      const splitURL = baseURL.split('#')
      scroller.scrollTo(splitURL[1], {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    }
  }, [isBrowser, baseURL])

  useEffect(() => {
    import("../utils/accordionPlugin").then(() => {
      $(".accordion").accordionPlugin();
    });
  }, []);
  useEffect(() => {
    if (!isBrowser) return;

    const scriptUrl = `https://gpp.markanthony.com/privacy-policy.js?entity=MAG&language=en&variables=true`;

    const appendScript = () => {
      const mainTag = document.querySelector('main');
      if (!mainTag) {
        setTimeout(appendScript, 1000); // Retry after 100ms
        return;
      }
  
      const script = document.createElement('script');
      script.src = scriptUrl;
      script.type = 'text/javascript';
      script.defer = true;
  
      mainTag.appendChild(script);
  
      script.onerror = () => {
        console.error('Failed to load the privacy policy script.');
        setLoading(false);
      };
  
      script.onload = () => {
        const fetchPrivacyPolicy = () => {
          fetch('https://gpp.markanthony.com/wp-json/custom/v1/privacy-policy?id=1566')
            .then((response) => {
              if (!response.ok) throw new Error('Failed to fetch privacy policy');
              return response.json();
            })
            .then((data) => {
              if (data && data.length > 0) {
                const post = data[0];
                const content = post.policy_content['en'] || 'Content not available in the selected language.';
                setPolicyContent(content);
              } else {
                console.error('No privacy policy found');
              }
              setLoading(false);
            })
            .catch((err) => {
              console.error('Error fetching privacy policy:', err);
              setLoading(false);
            });
        };
  
        fetchPrivacyPolicy();
      };
  
      return () => {
        if (mainTag && mainTag.contains(script)) {
          mainTag.removeChild(script);
        }
      };
    };
  
    appendScript();
  }, [isBrowser]);

  useEffect(() => {
    if (!isBrowser || !policyContent) return;
    const handleScrollToHash = () => {
      const hash = window.location.hash.substring(1);

      if (hash) {
        const $target = $("#" + hash);
        if ($target.length) {
          const headerHeight = $("header").outerHeight() || 100;
          $("html, body").animate(
            {
              scrollTop: $target.offset().top - headerHeight,
            },
            300
          );
        }
      }
    };
    $(document).ready(function () {
      $(".accordion").accordionPlugin();
      handleScrollToHash();
    });
    const onHashChange = () => {
      handleScrollToHash(); 
    };
    window.addEventListener("hashchange", onHashChange);
    return () => {
      window.removeEventListener("hashchange", onHashChange);
    };
  }, [isBrowser, policyContent]);

  return (
      <Layout currentPage="privacy-policy">
        <Seo
            title={pageData.meta_title  && pageData.meta_title.text}
            description={pageData.meta_description && pageData.meta_description.text}
        />
        <CommonPageBanner
            mobileBackgroundUrl={pageData.banner_background__mobile && pageData.banner_background__mobile.url}
            backgroundUrl={pageData.banner_background && pageData.banner_background.url}
        />
        <div className="container py-5">
        {loading && <div>Loading privacy policy...</div>}
        {policyContent && <div>{parse(policyContent)}</div>}
        </div>
      </Layout>
  )
}

export const query = graphql`
query PrivacyPolicyPageQuery{
    prismicGeneralDocumentsPage (uid: {eq: "privacy-policy"}) {
        _previewable
        data {
            banner_background {
                alt
                url
            }
            banner_background__mobile {
                alt
                copyright
                url
            }

            meta_description {
                text
            }
            meta_title {
                text
            }
            page_content {
                html
                text
            }
        }
    }
}
`
export default withPrismicPreview(PrivacyPolicyPage)